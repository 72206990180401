import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from "layout/layout"
import BlogPostSection from 'components/BlogPostSection'
import SEO from 'components/seo'

const PostsStyle = {
  display: `flex`,
  flexDirection: `column`,
  margin: `0 0 48px 0`,
}

const PagePadding = {
  padding: `0`,
}

class BlogPage extends React.Component {

  groupPostsByYear = (posts) => {

    const dateKeyStorage = {}

    posts.forEach(function(post) {
      const postDate = new Date(post.node.publicationDate)
      const postYear = postDate.getFullYear();
      
      if (postYear in dateKeyStorage) {
        const posts = dateKeyStorage[postYear]
        posts.push(post.node)
        dateKeyStorage[postYear] = posts
      } else {
        dateKeyStorage[postYear] = [post.node]
      }
    });

    return dateKeyStorage
  }

  render() {
    // const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const sections = this.groupPostsByYear(posts)
    const postKeys = Object.keys(sections)

    return (
      <Layout location={this.props.location}>
        <div className="container">
          <div className="row">
            <SEO title={`Blog`}/>
            <div className="col-12" style={PagePadding}>
              <h1>Blog</h1>
            </div>
            <div className="col-12">
              <div style={PostsStyle}>
                {postKeys.map(year => (
                  <BlogPostSection year={year} posts={sections[year]} key={year} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPage

// publicationDate(formatString: "MMMM Do, YYYY")

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publicationDate], order: DESC }) {
      edges {
        node {
          title
          snippet
          slug
          publicationDate
          tags
          content {
            childMarkdownRemark {
              id
              html
              timeToRead
              wordCount {
                words
              }
            }
          }
        }
      }
    }
  }
`