import React from 'react'
import { Link } from 'gatsby'
import theme from 'utils/theme'
import { debounce } from 'lodash'

const PostPreviewStyle = {
  display: `flex`,
  margin: `8px 0 0 0`,
  padding: `0`,
}

const PostPreviewContent = {}

const PostPreviewTitle = {
  marginBottom: 0,
  fontWeight: theme.fontWeight.medium,
}

const TagSectionStyle = {
  display: `flex`,
  flexWrap: `wrap`,
  margin: `8px 0 0 0`,

}

const PostTitleLayoutStyle = {
  margin: `0px auto 0px 0px`,
}

const PostContentLayoutStyle = {
  margin: `0px auto 0px 0px`,
}

const TagStyle = {
  display: `flex`,
  justtifyContent: `center`,
  margin: `0 8px 8px 0`,
  padding: `6px 12px`,
  borderRadius: `16px`,
  backgroundColor: theme.colors.black20,
  width: `fit-content`,
}

const TagTextStyle = {
  fontSize: `14px`,
  lineHeight: `1.2em`,
  fontWeight: theme.fontWeight.medium,
  margin: 0,
  padding: 0,
  color: theme.colors.black80,
}

class PostPreview extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
    }

    this.handleWindowResize = this.handleWindowResize.bind(this);
  };

  componentDidMount() {
    this.handleWindowResize()

    window.addEventListener('resize', debounce(this.handleWindowResize, 200));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.handleWindowResize, 200));
  }

  handleWindowResize() {
    this.setState({ isMobile: window.innerWidth < 480 })
  }

  render() {

    const post = this.props.post
    const publicationDate = new Date(post.publicationDate)

    const postMonthShort = publicationDate.toLocaleString('default', { month: 'short' })
    const postMonthLong = publicationDate.toLocaleString('default', { month: 'long' })
    const postDay = publicationDate.getDay()

    const { isMobile } = this.state

    return (
      <div style={PostPreviewStyle}>
        <div style={PostTitleLayoutStyle} className="col-3 col-4-sm">
          <p>{`${isMobile ? postMonthShort : postMonthLong} ${postDay}`}</p>
        </div>
        <div style={PostContentLayoutStyle} className="col-9 col-8-sm">
          <div style={PostPreviewContent}>
            <p style={PostPreviewTitle}>
              <Link to={`/blog/${post.slug}`}>{post.title}</Link>
            </p>
            <div style={TagSectionStyle}>
              {post.tags.map(tag => (
                <div style={TagStyle} key={tag}>
                  <p style={TagTextStyle}>
                    {tag}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PostPreview;

/*
function ArticlePreview({ article }) {
  return (
    <div className={styles.preview}>
      <h3 className={styles.previewTitle}>
        <Link to={`/blog/${article.slug}`}>{article.title}</Link>
      </h3>
      <small>{article.publicationDate}</small>
      <p
        dangerouslySetInnerHTML={{
          __html: article.content.childMarkdownRemark.html,
        }}
      />
      {article.tags.map(tag => (
        <p className={styles.tag} key={tag}>
          {tag}
        </p>
      ))}
    </div>
  );
};
*/
