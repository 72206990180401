import React from 'react'
import { css } from 'emotion'

import PostPreview from 'components/PostPreview'
import theme from 'utils/theme'

const SectionStyle = {
  margin: `16px 0 0 0`,

  [`${theme.responsiveKeys.mobile}`]: {
    margin: `0`,    
  },
}

function BlogPostSection({ year, posts }) {
  return (
    <div className={css(SectionStyle)}>
      <h3>{year}</h3>
      {posts.map(post => (
        <PostPreview post={post} key={post.slug} />
      ))}
    </div>
  );
};

export default BlogPostSection;
